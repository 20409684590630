.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
  z-index: 999; /* Should be lower than the modal's z-index */
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 400px;
  width: 100%;
}

.header {
  text-align: center;
}

.content {
  margin-top: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

.actions {
  margin-top: 20px;
  text-align: right;
}

.cancelButton,
.confirmButton {
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
}

.cancelButton {
  background-color: #ccc;
}

.confirmButton {
  background-color: #4caf50;
  color: white;
}
