.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  backdrop-filter: blur(5px);
  z-index: 70;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: 3%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  width: 70%;
}

.previewImage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  width: max-content;
}

.modalPreviewImage {
  display: none;
}

.cropControls {
  margin-bottom: 10px;
}
.cropControls > * {
  margin-bottom: 3px;
}

.img {
  max-width: 60%;
}


.header {
  text-align: center;
}

.content {
  margin-top: 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

.actions {
  margin-top: 20px;
  text-align: right;
}

.cancelButton,
.confirmButton {
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
}

.cancelButton {
  background-color: #ccc;
}

.confirmButton {
  background-color: #4caf50;
  color: white;
}
