@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  font-family: 'Nunito Sans', sans-serif;
  color: #3a3a3a;
}

* {
  scroll-behavior: smooth;
}


.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #def9fa 0.89%,
    #bef3f5 17.23%,
    #9dedf0 42.04%,
    #7de7eb 55.12%,
    #5ce1e6 71.54%,
    #33bbcf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #def9fa -43.27%,
    #bef3f5 -21.24%,
    #9dedf0 12.19%,
    #7de7eb 29.82%,
    #5ce1e6 51.94%,
    #33bbcf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.sidebarClose {
  -webkit-animation: slide-inside 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-inside 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* Apply custom styles to the scrollbar */
::-webkit-scrollbar {
  width: 4px; /* Width of the vertical scrollbar */
  height: 7px; /* Adjust the height of the scrollbar track */
  cursor: pointer;
}

.no-scrollbar::-webkit-scrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none;
  display: none;
}

.scroll-container {
  overflow: hidden; /* This hides both horizontal and vertical scrollbars */
  cursor: pointer;
}
/* Track styles */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  cursor: pointer;
}
.scrollable-container::-webkit-scrollbar {
  width: 4px; /* Adjust the width as needed */
  cursor: pointer;
}
.scrollable-container::-webkit-scrollbar {
  height: 4px; /* Adjust the height of the scrollbar track */
  cursor: pointer;
}

/* Thumb styles */
::-webkit-scrollbar-thumb {
  background: #5E7CE8;
  border-radius: 5px;
  cursor: pointer;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-1200px);
    transform: translateY(-1200px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0px);
  }
}

@keyframes slide-inside {
  100%{
    -webkit-transform: translateY(1200px);
    transform: translateY(-1200px);
  }
  0%{
    -webkit-transform: translateY(100px);
    transform: translateY(-50px);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}

.pink__gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}


