.selector_prompt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .selector_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .selector_message {
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .blur_effect {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
  }
  .header {
    padding: 0;
  }

  .header h2 {
    margin: 0;
    color: white;
  }

  .actions {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    font: inherit;
    border: 1px solid #4f005f;
    margin: 5px;
    background: #4f005f;
    color: white;
    padding: 0.25rem 1rem;
    cursor: pointer;
  }
  .button_cards {
    height: 20px;
  }

  .button:hover,
  .button:active {
    background: #741188;
    border-color: #741188;
  }

  .button:focus {
    outline: none;
  }

  @media (min-width: 768px) {
    .modal {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }
